import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpRequest, HttpHeaders, HttpEventType, HttpErrorResponse } from '@angular/common/http';

import { Apiurlhelper } from 'app/helpers/apiurlhelper';
import { Clientapphelper } from 'app/helpers/clientapphelper';
import { Errorhelper } from 'app/helpers/errorhelper';

import { Responsemodel } from 'app/models/responsemodel';
import { Documentinfomodel } from 'app/models/documentinfomodel';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(
    private apiHelper: Apiurlhelper,
    private clientAppHelper: Clientapphelper,
    private errorHelper: Errorhelper,
    private httpClient: HttpClient
  ) { }

  uploadDocument(formData: FormData, id: string) {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .post(
        this.apiHelper.getApiUrl() + 'api/upload/gonioscopy?eyeprobid=' + id,
        formData,
        { headers: headers }
      )
      .pipe(
        map((response: Responsemodel) => { return response }),
        catchError((error: HttpErrorResponse) => this.errorHelper.handleError(error))
      );
  }

  uploadTreatmentPlanImages(formData: FormData, id: number) {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .post(
        this.apiHelper.getApiUrl() + 'api/upload/treatmentplan?patienttreatmentid=' + id,
        formData,
        { headers: headers }
      )
      .pipe(
        map((response: Responsemodel) => { return response }),
        catchError((error: HttpErrorResponse) => this.errorHelper.handleError(error))
      );
  }

  uploadProfilePic(formData: FormData, id: string) {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .post(
        this.apiHelper.getApiUrl() + 'api/v1/upload/profilepic?patientid=' + id,
        formData,
        { headers: headers }
      )
      .pipe(
        map((response: Responsemodel) => { return response }),
        catchError((error: HttpErrorResponse) => this.errorHelper.handleError(error))
      );
  }

  // Ophthalmology
  uploadGonioscopy(formData: FormData, findingsid: string, patientid: string) {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .post(
        this.apiHelper.getApiUrl() + 'api/v1/upload/gonioscopy?findingsid=' + findingsid + '&patientid=' + patientid,
        formData,
        { headers: headers }
      )
      .pipe(
        map((response: Responsemodel) => { return response }),
        catchError((error: HttpErrorResponse) => this.errorHelper.handleError(error))
      );
  }

  uploadPosteriorSegmentImg(formData: FormData, findingsid: string, patientid: string) {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .post(
        this.apiHelper.getApiUrl() + 'api/v1/upload/posteriorsegment?findingsid=' + findingsid + '&patientid=' + patientid,
        formData,
        { headers: headers }
      )
      .pipe(
        map((response: Responsemodel) => { return response }),
        catchError((error: HttpErrorResponse) => this.errorHelper.handleError(error))
      );
  }

  uploadAnteriorSegmentImg(formData: FormData, findingsid: string, patientid: string) {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .post(
        this.apiHelper.getApiUrl() + 'api/v1/upload/anteriorsegment?findingsid=' + findingsid + '&patientid=' + patientid,
        formData,
        { headers: headers }
      )
      .pipe(
        map((response: Responsemodel) => { return response }),
        catchError((error: HttpErrorResponse) => this.errorHelper.handleError(error))
      );
  }

  uploadOpticDiscImg(formData: FormData, findingsid: string, patientid: string) {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .post(
        this.apiHelper.getApiUrl() + 'api/v1/upload/opticdisc?findingsid=' + findingsid + '&patientid=' + patientid,
        formData,
        { headers: headers }
      )
      .pipe(
        map((response: Responsemodel) => { return response }),
        catchError((error: HttpErrorResponse) => this.errorHelper.handleError(error))
      );
  }

  uploadLabResult(formData: FormData, id: string) {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .post(
        this.apiHelper.getApiUrl() + 'api/v1/upload/labresult?patientid=' + id,
        formData,
        { headers: headers }
      )
      .pipe(
        map((response: Responsemodel) => response),
        catchError((error: HttpErrorResponse) => this.errorHelper.handleError(error))
      );
  }

}

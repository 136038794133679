import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Apiurlhelper } from 'app/helpers/apiurlhelper';
import { Clientapphelper } from 'app/helpers/clientapphelper';
import { Errorhelper } from 'app/helpers/errorhelper';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TreatmentPlanItemTemplateService {
 private authHeader: any;
 private apiBaseUrl: string;

  constructor(
    private apiHelper: Apiurlhelper,
    private clientAppHelper: Clientapphelper,
    private errorHelper: Errorhelper,
    private http: HttpClient
  ) { 

    this.apiBaseUrl = this.apiHelper.getApiUrl();
  }

  getTreatmentPlansTpl(): Observable<any> {
  
    return this.http.get<any>(`${this.apiBaseUrl}/api/v1/treatmentplantpl`)
      .pipe(
        map(response => {
          if (response) {
            response.forEach(tpl => {
              tpl['treatmentPlansString'] = '';

              for(var i = 0; i < tpl['treatmentPlans'].length; i++) {
                if (i > 0) {
                  tpl['treatmentPlansString'] = (tpl['treatmentPlansString'] + ', ');
                }

                tpl['treatmentPlansString'] = (tpl['treatmentPlansString'] + tpl['treatmentPlans'][i]['treatmentName']);
              }
            });
          }
          
          return response;
        })
      );
  }

  addTreatmentPlansTpl(obj: any, userid: string): Observable<any> {
  
    return this.http.post<any>(`${this.apiBaseUrl}/api/v1/treatmentplantpl/add/${userid}`, obj)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  deleteTreatmentPlansTpl(id: number): Observable<any> {
  
    return this.http.delete<any>(`${this.apiBaseUrl}/api/v1/treatmentplantpl/remove/${id}`, {})
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  updateTreatmentPlansTpl(obj: any, id: number, userid: string): Observable<any> {
  
    return this.http.put<any>(`${this.apiBaseUrl}/api/v1/treatmentplantpl/update/${id}/${userid}`, obj)
      .pipe(
        map(response => {
          return response;
        })
      );
  }
  
}

import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Apiurlhelper } from 'app/helpers/apiurlhelper';
import { Clientapphelper } from 'app/helpers/clientapphelper';
import { Errorhelper } from 'app/helpers/errorhelper';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TreatmentPlanItemService {
 private authHeader: any;
 private apiBaseUrl: string;

  constructor(
    private apiHelper: Apiurlhelper,
    private clientAppHelper: Clientapphelper,
    private errorHelper: Errorhelper,
    private http: HttpClient
  ) { 

    this.apiBaseUrl = this.apiHelper.getApiUrl();
  }

  getTreatmentPlans(): Observable<any> {
  
    return this.http.get<any>(`${this.apiBaseUrl}/api/v1/treatmentplan`)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  addTreatmentPlans(obj: any): Observable<any> {
  
    return this.http.post<any>(`${this.apiBaseUrl}/api/v1/treatmentplan/add`, obj)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  deleteTreatmentPlans(id: number): Observable<any> {
  
    return this.http.delete<any>(`${this.apiBaseUrl}/api/v1/treatmentplan/remove/${id}`, {})
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  updateTreatmentPlans(obj: any): Observable<any> {
  
    return this.http.put<any>(`${this.apiBaseUrl}/api/v1/treatmentplan/update`, obj)
      .pipe(
        map(response => {
          return response;
        })
      );
  }
  
}

import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Apiurlhelper } from 'app/helpers/apiurlhelper';
import { Clientapphelper } from 'app/helpers/clientapphelper';
import { Errorhelper } from 'app/helpers/errorhelper';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DiagnosisService {
 private authHeader: any;
 private apiBaseUrl: string;

  constructor(
    private apiHelper: Apiurlhelper,
    private clientAppHelper: Clientapphelper,
    private errorHelper: Errorhelper,
    private http: HttpClient
  ) { 

    this.apiBaseUrl = this.apiHelper.getApiUrl();
  }

  getDiagnosisList(): Observable<any> {
  
    return this.http.get<any>(`${this.apiBaseUrl}/api/v1/diagnosis`)
      .pipe(
        map(response => {
          for(var i = 0; i < response.length; i++) {
            response[i]['fulldesc'] = response[i]['code'] + ' - ' + response[i]['description'];
          }
          return response;

        })
      );
  }

  addDiagnosis(obj: any): Observable<any> {
  
    return this.http.post<any>(`${this.apiBaseUrl}/api/v1/diagnosis/add`, obj)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  deleteDiagnosis(id: number): Observable<any> {
  
    return this.http.delete<any>(`${this.apiBaseUrl}/api/v1/diagnosis/remove/${id}`, {})
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  updateDiagnosis(obj: any): Observable<any> {
  
    return this.http.put<any>(`${this.apiBaseUrl}/api/v1/diagnosis/update`, obj)
      .pipe(
        map(response => {
          return response;
        })
      );
  }
  
}

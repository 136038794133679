import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Apiurlhelper } from 'app/helpers/apiurlhelper';
import { Clientapphelper } from 'app/helpers/clientapphelper';
import { Errorhelper } from 'app/helpers/errorhelper';

import { Responsemodel } from 'app/models/responsemodel';
import { Findingsviewmodel } from 'app/models/viewmodels/findings/findingsviewmodel';
import { Retinafindingsaddviewmodel } from 'app/models/viewmodels/findings/retinafindingsaddviewmodel';
import { Glaucomafindingsaddviewmodel } from 'app/models/viewmodels/findings/glaucomafindingsaddviewmodel';

@Injectable({
  providedIn: 'root'
})
export class FindingsService {

  constructor(
    private apiHelper: Apiurlhelper,
    private clientAppHelper: Clientapphelper,
    private errorHelper: Errorhelper,
    private httpClient: HttpClient
  ) { }

  addRetinaFindings(findings: Retinafindingsaddviewmodel) {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .post(
        this.apiHelper.getApiUrl() + 'api/v1/findings/add',
        JSON.stringify(findings),
        { headers: headers }
      )
      .pipe(
        map((response: Responsemodel) => { return response }),
        catchError((error: HttpErrorResponse) => this.errorHelper.handleError(error))
      );
  }

  addGlaucomaFindings(findings: Glaucomafindingsaddviewmodel) {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .post(
        this.apiHelper.getApiUrl() + 'api/v1/findings/add',
        JSON.stringify(findings),
        { headers: headers }
      )
      .pipe(
        map((response: Responsemodel) => { return response }),
        catchError((error: HttpErrorResponse) => this.errorHelper.handleError(error))
      );
  }

  getPatientFindings(patientid: string): Observable<Findingsviewmodel[]> {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .get(
        this.apiHelper.getApiUrl() + 'api/v1/findings?patientid=' + patientid,
        { headers: headers }
      )
      .pipe(
        map((response: Findingsviewmodel[]) => response)
      );
  }

  getPatientLatestFindings(patientid: string): Observable<Findingsviewmodel> {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .get(
        this.apiHelper.getApiUrl() + 'api/v1/findings/latest?patientid=' + patientid,
        { headers: headers }
      )
      .pipe(
        map((response: Findingsviewmodel) => response)
      );
  }

  getPatientLatestFindingsByType(patientid: string, eyeType: string ): Observable<Findingsviewmodel> {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .get(
        this.apiHelper.getApiUrl() + 'api/v1/findings/latest/' + eyeType + '?patientid=' + patientid,
        { headers: headers }
      )
      .pipe(
        map((response: Findingsviewmodel) => response)
      );
  }

  getFindingsInfo(findingsid: string, patientid: string): Observable<Findingsviewmodel> {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .get(
        this.apiHelper.getApiUrl() + 'api/v1/findings/result?findingsid=' + findingsid + '&patientid=' + patientid,
        { headers: headers }
      )
      .pipe(
        map((response: Findingsviewmodel) => response)
      );
  }

}

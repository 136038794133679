import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { Apiurlhelper } from '../helpers/apiurlhelper';
import { Clientapphelper } from '../helpers/clientapphelper';
import { Errorhelper } from '../helpers/errorhelper';

import { Patientmodel } from '../models/patientmodel';
import { Patientinfomodel } from '../models/patientinfomodel';
import { Responsemodel } from '../models/responsemodel';
import { Patientviewinfomodel } from '../models/patientviewinfomodel';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(
    private apiHelper: Apiurlhelper,
    private clientAppHelper: Clientapphelper,
    private errorHelper: Errorhelper,
    private httpClient: HttpClient
  ) { }

  addPatient(patient: Patientmodel) {
    patient.userId = localStorage.getItem('currentUserId');
    
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .post(
        this.apiHelper.getApiUrl() + 'api/v1/patient/add',
        JSON.stringify(patient),
        { headers: headers }
      )
      .pipe(
        map((response: Responsemodel) => { return response }),
        catchError((error: any) => this.errorHelper.handleError(error))
      );
  }

  getPatients(): Observable<Patientmodel[]> {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .get(
        this.apiHelper.getApiUrl() + 'api/v1/patient/' + localStorage.getItem('currentUserId'),
        { headers: headers }
      )
      .pipe(
        map((response: Patientmodel[]) => response)
      );
  }

  getPatientInfo(id: string): Observable<Patientmodel> {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .get(
        this.apiHelper.getApiUrl() + 'api/v1/patient?userid=' + localStorage.getItem('currentUserId') + '&patientid=' + id,
        { headers: headers }
      )
      .pipe(
        map((response: Patientmodel) => response)
      );
  }

  updatePatientInfo(patient: Patientmodel): Observable<Responsemodel> {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Content-type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .put(
        this.apiHelper.getApiUrl() + 'api/v1/patient/update',
        JSON.stringify(patient),
        { headers: headers }
      )
      .pipe(
        map((response: Responsemodel) => { return response })
      );
  }

  deletePatient(id: number): Observable<Responsemodel> {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .delete(
        this.apiHelper.getApiUrl() + 'api/v1/patient/delete/' + id + '/' + localStorage.getItem('currentUserId'),
        { headers: headers }
      )
      .pipe(
        map((response: Responsemodel) => { return response }),
        catchError((error: any) => this.errorHelper.handleError(error))
      );
  }

}

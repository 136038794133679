import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, catchError, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Apiurlhelper } from '../helpers/apiurlhelper';
import { Clientapphelper } from '../helpers/clientapphelper';
import { Errorhelper } from '../helpers/errorhelper';

import { Treatmentmodel } from '../models/treatmentmodel';
import { Responsemodel } from '../models/responsemodel';
import { Treatmentsalesaddmodel } from 'app/models/treatmentsalesaddmodel';
import { Treatmentsalesviewmodel } from 'app/models/viewmodels/sales/treatmentsalesviewmodel';
import { Treatmentsalestotalviewmodel } from 'app/models/viewmodels/sales/treatmentsalestotalviewmodel';

@Injectable({
  providedIn: 'root'
})
export class TreatmentService {

  constructor(
    private apiHelper: Apiurlhelper,
    private clientAppHelper: Clientapphelper,
    private errorHelper: Errorhelper,
    private httpClient: HttpClient
  ) { }

  addTreatment(treatment: Treatmentmodel) {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .post(
        this.apiHelper.getApiUrl() + 'api/v1/treatment/add',
        JSON.stringify(treatment),
        { headers: headers }
      )
      .pipe(
        map((response: Responsemodel) => { return response }),
        catchError((error: HttpErrorResponse) => this.errorHelper.handleError(error))
      );
  }

  getTreatments(id: string): Observable<Treatmentmodel[]> {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .get(
        this.apiHelper.getApiUrl() + 'api/v1/treatment/list?userid=' + id,
        { headers: headers }
      )
      .pipe(
        map((response: Treatmentmodel[]) => { return response }),
        catchError((error: any) => this.errorHelper.fetchError(error))
      );
  }

  getTreatment(id: number): Observable<Treatmentmodel> {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .get(
        this.apiHelper.getApiUrl() + 'api/treatment/' + id + '/info',
        { headers: headers }
      )
      .pipe(
        map((response: Treatmentmodel) => { return response }),
        catchError((error: any) => this.errorHelper.fetchError(error))
      );
  }

  getTreatmentInfo(id: string): Observable<Treatmentmodel> {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .get(
        this.apiHelper.getApiUrl() + 'api/v1/treatment/info?id=' + id,
        { headers: headers }
      )
      .pipe(
        map((response: Treatmentmodel) => response),
        catchError((error: any) => this.errorHelper.fetchError(error))
      );
  }

  filterTreatment(treatment: string) {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    var conditions=  this.httpClient
      .get(
        this.apiHelper.getApiUrl() + 'api/treatment/filter?treatmentcode=' + treatment,
        { headers: headers }
      )
      .pipe(
        debounceTime(500),
        map((response: Treatmentmodel[]) => { return response })
      );

    return conditions;
  }

  deleteTreatment(id: number) {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .delete(
        this.apiHelper.getApiUrl() + 'api/treatment/delete/' + id,
        { headers: headers }
      )
      .pipe(
        map((response: Responsemodel) => { return response }),
        catchError((error: any) => this.errorHelper.fetchError(error))
      );
  }

  updateTreatment(treatment: Treatmentmodel): Observable<Responsemodel> {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Content-type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .put(
        this.apiHelper.getApiUrl() + 'api/treatment/update',
        treatment,
        { headers: headers }
      )
      .pipe(
        map((response: Responsemodel) => { return response }),
        catchError((error: any) => this.errorHelper.fetchError(error))
      );
  }

  /* TREATMENT SALES */
  addTreatmentSales(treatment: Treatmentsalesaddmodel) {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .post(
        this.apiHelper.getApiUrl() + 'api/v1/treatment/sales/add',
        JSON.stringify(treatment),
        { headers: headers }
      )
      .pipe(
        map((response: Responsemodel) => response),
        catchError((error: HttpErrorResponse) => this.errorHelper.handleError(error))
      );
  }

  getTreatmentSales(id: string): Observable<Treatmentsalesviewmodel[]> {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .get(
        this.apiHelper.getApiUrl() + 'api/v1/treatment/sales?userid=' + id,
        { headers: headers }
      )
      .pipe(
        map((response: Treatmentsalesviewmodel[]) => response),
        catchError((error: any) => this.errorHelper.fetchError(error))
      );
  }

  getDailyTotalSales(id: string): Observable<number> {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .get(
        this.apiHelper.getApiUrl() + 'api/v1/treatment/sales/daily?userid=' + id,
        { headers: headers }
      )
      .pipe(
        map((response: number) => response),
        catchError((error: any) => this.errorHelper.fetchError(error))
      );
  }

  getTreatmentTotalSales(id: string): Observable<Treatmentsalestotalviewmodel[]> {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .get(
        this.apiHelper.getApiUrl() + 'api/v1/treatment/sales/total?userid=' + id,
        { headers: headers }
      )
      .pipe(
        map((response: Treatmentsalestotalviewmodel[]) => response),
        catchError((error: any) => this.errorHelper.fetchError(error))
      );
  }
  /* END */

}

export class Patientmodel {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  address: string;
  dob: string;
  profession: string;
  contactNo: string;
  imgPath: string;
  userId: string;
}
